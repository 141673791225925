import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../components/button'
import { Container } from '../components/container'
import { LocalizedLink } from '../components/localized-link'
import { useLangContext } from '../utils/lang'

// import { Link } from 'gatsby';
const ThankYouAffiliate = () => {
  const { lang } = useLangContext()
  const { t } = useTranslation()

  const [paymentStatus, setPaymentStatus] = useState(null)
  const getFormName = localStorage.getItem('FormName')
  const parsedData = JSON.parse(localStorage.getItem('Calculation-data'))
  const getConversationData = JSON.parse(localStorage.getItem('conversation'))
  const parsedOnlineData = JSON.parse(localStorage.getItem('order-info'))
  const isWeekLength6 =
    parsedOnlineData && parsedOnlineData.isOrderFoodWeekMeal5DaysLength
      ? '5'
      : '6'
  const isPaymentOnline =
    parsedOnlineData && parsedOnlineData.onlinePayment
      ? 'online-card'
      : 'faktura'
  const deliveryTimeRange =
    parsedOnlineData && parsedOnlineData.deliveryTime &&
    `${parsedOnlineData.deliveryTime[0]}-${parsedOnlineData.deliveryTime[1]}`
  useEffect(() => {
    let urlString = document.location.href
    let url = new URL(urlString)
    const status = new URL(url).searchParams.get('status') || null
    setPaymentStatus(status)
  }, [])

  const pushDataLayerEvent = formName => {
    let event
    let pageURL
    let variables = {}

    switch (formName) {
      case 'vege-objednavka':
        event = 'conversion_vegetarian_menu_order'
        pageURL = '/thank-you-affiliate'
        break
      case 'vege-objednavka_en':
        event = 'conversion_vegetarian_menu_order'
        pageURL = '/thank-you-affiliate'
        break
      case 'hromadna-objednavka':
        event = 'conversion_multi_order'
        pageURL = '/thank-you-affiliate'
        break
      case 'hromadna-objednavka_en':
        event = 'conversion_multi_order'
        pageURL = '/thank-you-affiliate'
        break
      case 'konzultace-objednavka':
        event = 'conversion_nutritionist_consultation'
        pageURL = '/thank-you-affiliate'
        break
      case 'konzultace-dietolog':
        event = 'conversion_nutritionist_consultation'
        pageURL = '/thank-you-affiliate'
        break
      case 'konzultace-dietolog_en':
        event = 'conversion_nutritionist_consultation'
        pageURL = '/thank-you-affiliate'
        break
      case 'darkovy-certifikat':
        event = 'conversion_gift_voucher'
        pageURL = '/thank-you-affiliate'

        break
      case 'conversion-callback':
        event = 'conversion_callback'
        pageURL = '/thank-you-affiliate'
        break
      case 'demo-objednavka':
        event = 'conversion_demo_order'
        pageURL = '/thank-you-affiliate'
        break
      case 'demo-objednavka_en':
        event = 'conversion_demo_order'
        pageURL = '/thank-you-affiliate'
        break
      case 'order-call':
        event = 'conversion_callback'
        pageURL = `/thank-you-affiliate/price_daily=${getConversationData.price}&&program=${getConversationData.program}&&program_length=${getConversationData.lenght}&&meal_count
    =${getConversationData.numberOfMeals}&&person_count=${getConversationData.person}&&testovani=${getConversationData.testovani}&&coupon=${getConversationData.promo}&&kcal_count=${getConversationData.kalorie}&&menu_choice=${getConversationData.menu}&&sex_choice=${getConversationData.sex}&&week_length=${getConversationData.weekLenght}`
        variables = {
          price_daily: getConversationData.price_daily,
          program: getConversationData.program,
          program_length: getConversationData.lenght,
          meal_count: getConversationData.numberOfMeals,
          person_count: getConversationData.person,
          testovani: getConversationData.testovani,
          coupon: getConversationData.promo,
          kcal_count: getConversationData.kalorie,
          menu_choice: getConversationData.menu,
          sex_choice: getConversationData.sex,
          week_length: getConversationData.weekLenght,
        }
        break
      case 'kalkulacka':
        event = 'conversion_calculator'
        pageURL = `/thank-you-affiliate/price_daily=${parsedData.price}&&program=${parsedData.plan}&&program_length=${parsedData.program}&&meal_count
        =${parsedData.menu}&&person_count=${parsedData.osob}&&testovani=${parsedData.testovani}&&coupon=${parsedData.promo}`
        variables = {
          price_daily: parsedData.price,
          program: parsedData.plan,
          program_length: parsedData.program,
          meal_count: parsedData.menu,
          person_count: parsedData.osob,
          testovani: parsedData.testovani,
          coupon: parsedData.promo,
        }
        break
      case 'kalkulacka_en':
        event = 'conversion_calculator'
        pageURL = `/thank-you-affiliate/price_daily=${parsedData.price}&&program=${parsedData.plan}&&program_length=${parsedData.program}&&meal_count
=${parsedData.menu}&&person_count=${parsedData.osob}&&testovani=${parsedData.testovani}&&coupon=${parsedData.promo}`
        variables = {
          price_daily: parsedData.price,
          program: parsedData.plan,
          program_length: parsedData.program,
          meal_count: parsedData.menu,
          person_count: parsedData.osob,
          testovani: parsedData.testovani,
          coupon: parsedData.promo,
        }
        break
      case 'online-order':
        event = 'conversion_online_order'
          pageURL = `/thank-you/price_daily=${parsedOnlineData.price}&&program=${parsedOnlineData.Program}&&program_length=${parsedOnlineData.length}&&meal_count=${parsedOnlineData.numberOfMeals}&&person_count=${parsedOnlineData.NumberOfPeople}&&testovani
        =${parsedOnlineData.testovani}&&coupon=${parsedOnlineData.promo}&&kcal_count=${parsedOnlineData.kcal}&&menu_choice=${parsedOnlineData.CustomerMenu}&&sex_choice=${parsedOnlineData.CustomerGender}&&week_length=${isWeekLength6}&&company_order=${parsedOnlineData.isCompanyOrder}&&city=${parsedOnlineData.city}&&payment_method=${isPaymentOnline}&&delivery_time_range=${deliveryTimeRange}&&price=${parsedOnlineData.price}&&discount=${parsedOnlineData.PriceDiscount}`
          variables = {
            price_daily: parsedOnlineData.price_daily,
            program: parsedOnlineData.Program,
            program_length: parsedOnlineData.length,
            meal_count: parsedOnlineData.numberOfMeals,
            person_count: parsedOnlineData.NumberOfPeople,
            testovani: parsedOnlineData.testovani,
            coupon: parsedOnlineData.promo,
            kcal_count: parsedOnlineData.kcal,
            menu_choice: parsedOnlineData.CustomerMenu,
            sex_choice: parsedOnlineData.CustomerGender,
            week_length: isWeekLength6,
            company_order: parsedOnlineData.isCompanyOrder,
            city: parsedOnlineData.city,
            payment_method: isPaymentOnline,
            delivery_time_range: deliveryTimeRange,
            price: parsedOnlineData.price,
            discount: parsedOnlineData.PriceDiscount,
          }
        break
      default:
        break
    }

    if (event) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: event,
          pageURL: pageURL,
          ...variables
        })
    }
  }

  useEffect(() => {
    pushDataLayerEvent(getFormName)

  }, [getFormName])
  
  useEffect(() => {
    window.dataLayer = window.dataLayer || []

    window.dataLayer &&
      window.dataLayer.push({
        event: 'purchase',
        pageURL: window.location.pathname,
      })
  }, [])

  function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  // AB-test to display blueghost-version or current-version components
  const [websiteVersion, setWebsiteVersion] = useState("")
  const webVersions = ['new-calc-A', 'new-calc-B']

  useEffect(() => {
    const curVersion = localStorage.getItem("website-version")
    const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
    const randomVersion = webVersions[randomIndex]
    if (!curVersion || curVersion === undefined || !webVersions.includes(curVersion)) {
      localStorage.setItem("website-version", randomVersion)
      setWebsiteVersion(randomVersion)
    } else {
      setWebsiteVersion(curVersion)
    }
  }, [])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        pageURL: "/thank-you-affiliate",
        testovani: websiteVersion,
        event: "ga.pageview"

      })
  }, [websiteVersion])

  return (
    <Container className="text-center">
      <h1 className="text-center" style={{ marginTop: '100px' }}>
        {t('forms.TY.titlePart1')}
        <br />
        <span style={{ color: `var(--color-green)` }}>
          {' '}
          {t('forms.TY.titlePart2')}
        </span>
      </h1>
      {paymentStatus && <h2>{paymentStatus}</h2>}
      <Button type="primary">
        <LocalizedLink to="/"> {t('forms.TY.linkBack')}</LocalizedLink>
      </Button>
      <div style={{ marginBottom: '100px' }} />
    </Container>
  )
}

export default ThankYouAffiliate
